import React, { memo, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useParams } from "@tanstack/react-router";
import { ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { ConfirmDialog } from "@/confirm_dialog";
import { Icon } from "@/icon/icon";
import { Badge } from "@/ui/badge";
import { Button } from "@/ui/button";
import { Checkbox } from "@/ui/checkbox";
import { Input } from "@/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import RewardOrderPopup from "../RewardOrder";

import { useCreateTagV1MarketplaceTagsPost, useDeleteTagV1MarketplaceTagsTagIdDelete } from "~/api/marketplace/marketplace.gen";
import { HighlightedCollectionType } from "~/api/model";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { TagWithStatusAndRewardCount, useMarketplace } from "~/providers/marketplace";

const TagsCollectionsPart = memo(() => {
  const params = useParams({ from: "/_auth/$market/marketplace" });
  const { tags, refetchMarketplace } = useMarketplace();

  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedTagForDelete, setSelectedTagForDelete] = useState<TagWithStatusAndRewardCount | undefined>(undefined);
  const [selectedTag, setSelectedTag] = useState<TagWithStatusAndRewardCount | null>(null);
  const [mappedTags, setMappedTags] = useState<TagWithStatusAndRewardCount[]>([]);

  const createTagMutation = useCreateTagV1MarketplaceTagsPost({
    mutation: {
      onSuccess: () => {
        refetchMarketplace();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const deleteTagMutation = useDeleteTagV1MarketplaceTagsTagIdDelete({
    mutation: {
      onSuccess: () => {
        refetchMarketplace();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  useEffect(() => {
    setMappedTags(tags);
  }, [tags]);

  const deleteTagFn = () => {
    if (selectedTagForDelete && selectedTagForDelete._id) {
      deleteTagMutation.mutate({
        tagId: selectedTagForDelete._id,
      });
    }
  };

  const createTagFn = (name: string) => {
    createTagMutation.mutate({ data: { name, market: params.market } });
  };

  const tagsColumns: ColumnDef<TagWithStatusAndRewardCount>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />,
    },
    {
      accessorKey: "name",
      header: "Tag Name",
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const status: string[] = row.getValue("status");
        return (
          <div className="flex gap-1.5">
            {status.map((status, index) => (
              <Badge key={`tag-status-${index}`} variant={status === "recommended" ? "default" : "secondary"}>
                {status}
              </Badge>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: "publishedCount",
      header: "Published rewards",
    },
    {
      accessorKey: "unpublishedCount",
      header: "unpublished rewards",
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            {row.original.status.length > 0 ? (
              `Tag is : ${row.original.status.join(",")}`
            ) : (
              <Button
                variant="ghost"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setSelectedTagForDelete(row.original);
                  setOpenConfirmDialog(true);
                }}
              >
                <Icon icon="Trash" className="text-red-500" />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data: mappedTags,
    columns: tagsColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    initialState: {
      columnVisibility: {
        select: false,
      },
    },
  });

  const openDialogFn = (tag: TagWithStatusAndRewardCount) => {
    setSelectedTag(tag);
    setOpenDialog(true);
  };

  return (
    <>
      <h3>Tags</h3>
      <div className="w-full">
        <div className="rounded-md">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className="">
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.columnDef.id === "actions" ? (
                          <div>
                            <Input
                              placeholder="Search tags"
                              value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
                              onChange={(event) => table.getColumn("name")?.setFilterValue(event.target.value)}
                            />
                          </div>
                        ) : null}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              <TableRow className="border-0">
                <TableCell className="p-3" />
              </TableRow>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} data-state={row.getIsSelected() && "selected"} onClick={() => openDialogFn(row.original)}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={tagsColumns.length} className="h-24 text-center">
                    {table.getColumn("name") && table.getColumn("name")?.getFilterValue() ? (
                      <Button onClick={() => createTagFn(table.getColumn("name")?.getFilterValue() as string)}>
                        Create tag - {table.getColumn("name")?.getFilterValue() as string}
                      </Button>
                    ) : (
                      "No results found."
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <ConfirmDialog
          openDialog={openConfirmDialog}
          setOpenDialog={setOpenConfirmDialog}
          title="Are you sure you want to delete this tag?"
          confirmAction={deleteTagFn}
          confirmText="Delete"
        />
        {selectedTag &&
          <RewardOrderPopup
            parent={selectedTag}
            parentType={HighlightedCollectionType.Tag}
            refetch={refetchMarketplace}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            showDelete={false}
          />
        }
      </div>
    </>
  );
});

TagsCollectionsPart.displayName = "TagsCollectionsPart";

export default TagsCollectionsPart;
