import { ReactNode, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { Table, TableBody, TableCell, TableRow } from "@/ui/table";

import { UserGenders, UserModelOutput } from "~/api/model";
import { useUpdateUserProfileV1UsersUserIdUpdateUserProfilePatch } from "~/api/users/users.gen";
import { useGetCountryOptions } from "~/helpers/country-list";
import { convertToUsableDate } from "~/helpers/date-formatting";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Form } from "~/oldComponents/form/Form";
import type { Field } from "~/oldComponents/form/types";


interface PersonalDataSectionProps {
  user: UserModelOutput;
  refetch: () => void;

}

export function PersonalDataSection({ user, refetch }: PersonalDataSectionProps) {
  const queryClient = useQueryClient();

  const [comments, setComments] = useState("");
  const [profile, setProfile] = useState<{
    first_name: string;
    last_name: string;
    postal_code: string;
    city: string;
    street: string;
    country: string;
    profile_email: string;
    email: string;
    gender: UserGenders | undefined;
    birthdate: Date | undefined;
  }>({
    first_name: "",
    last_name: "",
    postal_code: "",
    city: "",
    street: "",
    country: "",
    profile_email: "",
    email: "",
    gender: undefined,
    birthdate: undefined,
  });

  useEffect(() => {
    if (user) {
      setComments(user.comments || "");
      setProfile({
        first_name: user.profile?.firstName || "",
        last_name: user.profile?.lastName || "",
        postal_code: user.profile?.address?.postalCode || "",
        city: user.profile?.address?.city || "",
        street: user.profile?.address?.street || "",
        country: user.profile?.address?.country || "",
        profile_email: user.profile?.email || "",
        email: user.email || "",
        gender: user.profile?.gender || undefined,
        birthdate: convertToUsableDate(user.profile?.birthdate) || undefined,
      });
    }
  }, [user]);

  const userPersonalData = [
    { label: "User ID:", field: user?._id },
    { label: "Status:", field: user?.status },
    { label: "Created at:", field: user?.createdAt ? new Date(user?.createdAt).toDateString() : "-" },
    { label: "Last updated:", field: user?.updatedAt ? new Date(user?.updatedAt).toDateString() : "-" },
    { label: "Wallet balance:", field: user?.walletBalance ? Math.round(user?.walletBalance) : "-" },
    { label: "Phone:", field: user?.phonenumber || "-" },
    { label: "Apple ID:", field: user?.appleId || "-" },
    { label: "Facebook ID:", field: user?.fbId || "-" },
    { label: "Google ID:", field: user?.googleId || "-" },
    { label: "Last used platform:", fields: user?.platform || "-" },
    { label: "language:", fields: user?.language || "-" },
    { label: "Total steps:", fields: user?.totalSteps },
    { label: "Spent points:", fields: user?.spentPoints },
    { label: "Is Premium:", fields: user?.isPremium ? "Yes" : "No" },
  ];

  const profileFields: Field[] = [
    {
      name: "firstName",
      label: "First name:",
      helpText: "User first name.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.first_name = e.target.value;
          return profile;
        });
      },
      value: profile.first_name,
    },
    {
      name: "lastName",
      label: "Last name:",
      helpText: "User last name.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.last_name = e.target.value;
          return profile;
        });
      },
      value: profile.last_name,
    },
    {
      name: "verifiedEmail",
      label: "Verified Email:",
      helpText: "The email that the user has verified.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setProfile((profile) => {
          profile.email = e.target.value;
          if (email) {
            profile.profile_email = "";
          }
          return profile;
        });
      },
      value: user?.email,
    },
    {
      name: "profileEmail",
      label: "Profile email:",
      helpText: "The old email field that is deprecated.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.profile_email = e.target.value;
          return profile;
        });
      },
      value: profile.profile_email,
    },
    {
      name: "birthdate",
      label: "Birhtdate:",
      helpText: "The day the user was born.",
      type: "date",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.birthdate = new Date(e.target.value);
          return profile;
        });
      },
      value: profile.birthdate?.toISOString().split("T")[0],
      required: false,
      empty: " ",
    },
    {
      name: "gender",
      label: "Gender:",
      helpText: "The gender of the user.",
      type: "search-select",
      change: (data: string) => {
        setProfile((profile) => {
          profile.gender = data as UserGenders;
          return profile;
        });
      },
      value: profile.gender,
      options: [
        {
          name: "Female",
          value: UserGenders.Female,
        },
        {
          name: "Male",
          value: UserGenders.Male,
        },
        {
          name: "Other",
          value: UserGenders.Other,
        },
        {
          name: "Prefer not to say",
          value: UserGenders.PreferNotToSay,
        },
      ],
      required: true,
      empty: " ",
    },
    {
      name: "country",
      label: "Country:",
      helpText: "User country.",
      type: "search-select",
      change: (data: string) => {
        setProfile((profile) => {
          profile.country = data;
          return profile;
        });
      },
      value: profile.country,
      options: useGetCountryOptions(),
      required: true,
      empty: " ",
    },
    {
      name: "city",
      label: "City:",
      helpText: "User city.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.city = e.target.value;
          return profile;
        });
      },
      value: profile.city,
    },
    {
      name: "street",
      label: "Street:",
      helpText: "User street.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.street = e.target.value;
          return profile;
        });
      },
      value: profile.street,
    },
    {
      name: "postalCode",
      label: "Postal code:",
      helpText: "User postal code.",
      type: "text",
      change: (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfile((profile) => {
          profile.postal_code = e.target.value;
          return profile;
        });
      },
      value: profile.postal_code,
    },
    {
      name: "comments",
      label: "Comments:",
      helpText: "Admin user comments about the user.",
      type: "text-editor",
      row: true,
      change: (data: string) => {
        setComments(data);
      },
      value: comments,
      custom: true,
    },
  ];

  const updateUserProfileMutation = useUpdateUserProfileV1UsersUserIdUpdateUserProfilePatch({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["user", user._id] });
        toast("User profile was successfully updated!");
        refetch();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onProfileSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await updateUserProfileMutation.mutateAsync({
      userId: user._id as string,
      data: {
        first_name: profile.first_name,
        last_name: profile.last_name,
        postal_code: profile.postal_code,
        city: profile.city,
        street: profile.street,
        country: profile.country,
        comments,
        profile_email: profile.profile_email,
        email: profile.email,
        birthdate: profile.birthdate?.toISOString(),
        gender: profile.gender,
      },
    });
  };

  return (
    <section className="user-data_section">
      <h5 className="data_section-header">Personal data</h5>
      <Table>
        <TableBody>
          {userPersonalData.map((data) => (
            <TableRow key={data.label}>
              <TableCell className="w-[300px] font-bold">{data.label}</TableCell>
              <TableCell>{data.field}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Form
        name="user-profile"
        fields={profileFields}
        onSubmit={onProfileSubmit}
        submitText="Update profile"
      />
    </section>
  );
}
