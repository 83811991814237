import React, { useCallback, useMemo } from "react";
import { useEffect, useState } from "react";
import { Active, DragEndEvent, Over } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, Row, useReactTable } from "@tanstack/react-table";
import { toast } from "sonner";

import { Combobox, ComboboxOption } from "@/combobox";
import { DnDDataTable, RowDragHandleCell } from "@/dnd_datatable";
import { Icon } from "@/icon/icon";
import { Button } from "@/ui/button";

import RewardOrderPopup from "../RewardOrder";

import { HighlightedCollectionType } from "~/api/model";
import { HighlightedCollectionWithCollection, useMarketplace } from "~/providers/marketplace";

export default function HighlightedCollectionsPart({
  mappedCollections,
  setMappedCollecitons,
}: {
  mappedCollections: HighlightedCollectionWithCollection[];
  setMappedCollecitons: React.Dispatch<React.SetStateAction<HighlightedCollectionWithCollection[]>>;
}) {
  const { categories, tags, businessRules, refetchMarketplace, market } = useMarketplace();

  const [localCollections, setLocalCollections] = useState<HighlightedCollectionWithCollection[]>([]);

  useEffect(() => {
    setLocalCollections(mappedCollections);
  }, [mappedCollections]);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState<HighlightedCollectionWithCollection | null>(null);

  const mappedOptions = useMemo(() => {
    const collectionData = localCollections.map((collection) => collection.data);

    const mappedCategories = categories
      .filter((category) => !collectionData.includes(category.category))
      .map((category) => ({
        value: category.category,
        label: category.name,
        type: "Category",
      }));

    const mappedTags = tags
      .filter((tag) => !collectionData.includes(tag._id as string))
      .map((tag) => ({
        value: tag._id as string,
        label: tag.name,
        type: "Tag",
      }));

    const mappedRules = businessRules
      .filter((rule) => !collectionData.includes(rule._id as string))
      .map((rule) => ({
        value: rule._id as string,
        label: rule.name,
        type: "BusinessRules",
      }));

    return [
      { value: "", label: "Categories", type: "separator" },
      ...mappedCategories,
      { value: "", label: "Tags", type: "separator" },
      ...mappedTags,
      { value: "", label: "Business Rules", type: "separator" },
      ...mappedRules,
    ];
  }, [categories, tags, localCollections, businessRules]);

  const createCollectionFn = useCallback(
    (option: ComboboxOption): void => {
      if (!option.value || typeof option.value !== "string") return;

      setLocalCollections((prevCollections) => {
        const newCollections = [...prevCollections];
        const order = prevCollections.length ? prevCollections[prevCollections.length - 1].order + 1 : 1;

        if (option.type === "Category") {
          const category = categories.find((c) => c.category === option.value);
          newCollections.push({
            order,
            data: option.value as string,
            type: (option.type as HighlightedCollectionType) || HighlightedCollectionType.Category,
            market,
            collection: category,
            id: option.value,
          });
        } else if (option.type === "Tag") {
          const tag = tags.find((t) => t._id?.toString() === option.value);
          newCollections.push({
            order,
            data: option.value as string,
            type: (option.type as HighlightedCollectionType) || HighlightedCollectionType.Tag,
            market,
            collection: tag,
            id: option.value,
          });
        } else if (option.type === "BusinessRules") {
          const rule = businessRules.find((rule) => rule._id?.toString() === option.value);
          newCollections.push({
            order,
            data: option.value as string,
            type: (option.type as HighlightedCollectionType) || HighlightedCollectionType.Tag,
            market,
            collection: rule,
            id: option.value,
          });
        } else {
          toast.info("Unknown type");
        }

        // Update both states at once
        setMappedCollecitons(newCollections);
        return newCollections;
      });
    },
    [setMappedCollecitons, categories, market, tags, businessRules],
  );

  const openDialogFn = (collection: HighlightedCollectionWithCollection) => {
    setSelectedCollection(collection);
    setOpenDialog(true);
  };

  const collectionColumns: ColumnDef<HighlightedCollectionWithCollection>[] = useMemo<ColumnDef<HighlightedCollectionWithCollection>[]>(() => {
    function deleteCollectionFn(id: string) {
      setLocalCollections((collections) => {
        const index = collections.findIndex((collection) => collection.id === id);
        const newCollections = [...collections];
        if (index > -1) {
          newCollections.splice(index, 1);
        }
        return newCollections;
      });
      setMappedCollecitons((collections: HighlightedCollectionWithCollection[]) => {
        const index = collections.findIndex((collection) => collection.id === id);
        const newCollections = [...collections];
        if (index > -1) {
          newCollections.splice(index, 1);
        }
        return newCollections;
      });
    }
    return [
      {
        id: "drag-handle",
        header: "",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
      },
      {
        id: "name",
        header: "Name",
        onClick: (event: React.MouseEvent, row: Row<HighlightedCollectionWithCollection>) => {
          openDialogFn(row.original);
        },
        cell: ({ row }) => row.original.collection?.name,
      },
      {
        id: "type",
        header: "Type",
        cell: ({ row }) => row.original.type,
      },
      {
        id: "publishedRewards",
        header: "Published rewards",
        onClick: (event: React.MouseEvent, row: Row<HighlightedCollectionWithCollection>) => {
          openDialogFn(row.original);
        },
        cell: ({ row }) => row.original.collection?.publishedCount,
      },
      {
        id: "unPublishedRewards",
        header: "Unpublished rewards",
        onClick: (event: React.MouseEvent, row: Row<HighlightedCollectionWithCollection>) => {
          openDialogFn(row.original);
        },
        cell: ({ row }) => row.original.collection?.unpublishedCount,
      },
      {
        id: "addCombobox",
        header: "",
        cell: ({ row }) => {
          return (
            <div className="flex justify-end">
              <Button
                variant="ghost"
                onClick={(event) => {
                  event.stopPropagation();
                  deleteCollectionFn(row.id);
                }}
              >
                <Icon icon="Close" />
              </Button>
            </div>
          );
        },
      },
    ];
  }, [setMappedCollecitons]);

  const table = useReactTable({
    data: localCollections,
    columns: collectionColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id || "",
  });

  // reorder rows after drag & drop
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over }: { active: Active; over: Over | null } = event;

      if (active && over && active.id !== over.id) {
        const oldIndex = localCollections.findIndex((collection) => collection.id === active.id);
        const newIndex = localCollections.findIndex((collection) => collection.id === over.id);
        const newOrder = arrayMove(localCollections, oldIndex, newIndex).map((col, index) => ({
          ...col,
          order: index + 1,
        }));

        // Single state update with memoized callback to prevent unnecessary re-renders
        setLocalCollections(newOrder);
        setMappedCollecitons(newOrder);
      }
    },
    [localCollections, setMappedCollecitons],
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between">
        <h3>Highlighed Collections</h3>
        <div className="flex justify-end">
          <Combobox
            className="w-60"
            searchName="Category or Tag"
            placeholder="Add to Collections"
            options={mappedOptions}
            onChange={createCollectionFn}
            value=""
          />
        </div>
      </div>

      <DnDDataTable
        table={table}
        columns={collectionColumns}
        handleDragEnd={handleDragEnd}
        dataIds={localCollections.map((collection) => collection.id as string)}
      />
      {selectedCollection?.collection &&
        <RewardOrderPopup
          parent={selectedCollection?.collection || null}
          parentType={selectedCollection?.type || HighlightedCollectionType.Tag}
          refetch={refetchMarketplace}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          showDelete={false}
        />
      }
    </div>
  );
}
