import React, { ReactNode } from "react";
import { useState } from "react";
import { createFileRoute } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import Tab from "@/tab";
import { Card } from "@/ui/card";

import { useCreateTestUsersV1UsersCreateTestUsersPost } from "~/api/users/users.gen";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { TabPage } from "~/oldComponents/layout/TabPage";
import { FormConfig, TableConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/users/test")({
  component: () => <TestUsersPage />,
});

function TestUsersPage() {
  const [userIds, setUserIds] = useState<string[]>([]);
  const [amount, setAmount] = useState<number>(0);

  const createTestUsersMutation = useCreateTestUsersV1UsersCreateTestUsersPost({
    mutation: {
      onMutate: () => {
        setUserIds([]);
      },
      onSuccess: (userIds) => {
        setAmount(0);
        setUserIds(userIds);
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await createTestUsersMutation.mutateAsync({ data: { number: amount } });
  };

  const createUsersForm: FormConfig = {
    name: "create-test-users-form",
    fields: [
      {
        name: "title",
        label: "User amount:",
        type: "number",
        helpText: "Amount of test users to create.",
        value: amount,
        change: (e: React.ChangeEvent<HTMLInputElement>) => setAmount(parseInt(e.target.value)),
        row: true,
        min: 1,
        max: 50,
        required: true,
      },
    ],
    onSubmit,
    submitText: "Create test users",
  };

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: "User ID",
        format: (item: string) => item,
      },
    ],
    objects: userIds,
    emptyMessage: "Use form above to create test users",
  };

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Test Users</h2>
          <p className="text-foreground">
            Create test users for partners so they can test the partner app integration (dev/staging environment only)
          </p>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={false} to="/users" title="Users" />
          <Tab active to="/users/test" title="Test Users" />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <TabPage form={createUsersForm} table={table} />
        </Card>
      </div>
    </section>
  );
}
