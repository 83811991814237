import React, { FocusEvent, KeyboardEvent, MouseEvent, useEffect, useState } from "react";

import { Option } from "../form/types";

import "./search-select.scss";

type SelectProps = {
  change: (data: string) => void;
  name: string;
  value?: string;
  empty?: string;
  optionList?: Option[];
  required?: boolean;
  disabled?: boolean;
};

export const SearchableSelect: React.FC<SelectProps> = ({ optionList, change, value, name, required, disabled, empty }) => {
  const [searchTerm, setSearchTerm] = useState(empty || "");
  const [localState, setLocalState] = useState<string>(value || "");
  const [displayedTerm, setDisplayedTerm] = useState<string | undefined>(undefined);

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value || "");
  };

  useEffect(() => {
    if (value) {
      const selectedOption = optionList?.find((option) => option.value === value);
      if (selectedOption && selectedOption.name !== searchTerm) {
        setSearchTerm(selectedOption.name);
      }
    } else if (empty && searchTerm !== empty) {
      setSearchTerm(empty);
    }
  }, [value, optionList, searchTerm, empty]);

  const allOptions = (optionList || []).sort((a, b) => {
    if (a.value === "") return -1;
    if (a.name < b.name) return -1;
    if (b.name < a.name) return 1;
    return 0;
  });

  const emptyList = [];
  if (empty) emptyList.push({ value: "", name: empty });
  const processedOptions = [...emptyList, ...allOptions];

  const filteredOptions = processedOptions.filter((option) =>
    option.name.toLowerCase().includes((searchTerm || "").toLowerCase())
  );

  const onSelect = (event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    setDisplayedTerm(undefined);
    const newValue = target.dataset.value || "";
    setLocalState(newValue);
    target.parentElement?.classList.remove("search-select__dropdown--active");
    target.parentElement?.parentElement?.querySelector(".search-select__backdrop")?.classList.remove("search-select__backdrop--active");
    if (target.dataset.name) setSearchTerm(target.dataset.name);
    change(newValue);
  };

  const openDropdown = (event: FocusEvent<HTMLInputElement | HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    setDisplayedTerm(searchTerm);

    setSearchTerm("");

    target.parentElement?.querySelector(".search-select__dropdown")?.classList.add("search-select__dropdown--active");
    target.parentElement?.querySelector(".search-select__backdrop")?.classList.add("search-select__backdrop--active");
  };

  const closeDropdown = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    setTimeout(() => {
      if (displayedTerm) setSearchTerm(displayedTerm);
      target.parentElement?.querySelector(".search-select__backdrop")?.classList.remove("search-select__backdrop--active");
      target.parentElement?.querySelector(".search-select__dropdown")?.classList.remove("search-select__dropdown--active");
    }, 20);
  };

  return (
    <div className="search-select">
      <div className="search-select__backdrop" onClick={closeDropdown} />
      <input
        className="search-select__input input bg-background text-foreground"
        type="text"
        value={searchTerm || ""}
        onChange={handleSearchInputChange}
        list="options"
        disabled={disabled}
        onFocus={openDropdown}
      />
      <input type="hidden" value={localState || ""} required={required} name={name} />
      <div className="search-select__dropdown border">
        {filteredOptions.map((option, index) => (
          <div
            tabIndex={0}
            className="search-select__dropdown-item bg-background text-foreground hover:bg-card"
            key={`${name}-${option.value}-${index}`}
            data-value={option.value}
            data-name={option.name}
            onClick={onSelect}
            onKeyDown={(e) => {
              if (e.key === "Enter") onSelect(e);
            }}
          >
            {option.name}
          </div>
        ))}
      </div>
    </div>
  );
};
