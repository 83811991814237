import React, { ReactNode } from "react";
import { useState } from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";
import { PointLinkModel } from "~/api/model";
import { useCreatePointLinkV1PointLinksPost } from "~/api/point-links/point-links.gen";
import { parseMarketCountries } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/points-links/create")({
  component: () => <CreatePointsLinkPage />,
});

const CreatePointsLinkPage = () => {
  const navigate = useNavigate();

  const [pointLink, setPointLink] = useState<PointLinkModel>({
    name: "",
    country: "",
    reward: 0,
  });

  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      initialData: [],
    },
  });

  const createPointLinkMutation = useCreatePointLinkV1PointLinksPost({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
      onSuccess: () => {
        toast("Points link was successfully created!");
        navigate({ to: "/points-links" });
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();

    await createPointLinkMutation.mutateAsync({ data: pointLink });
  };

  const page: PageConfig = {
    title: "Create a points link",
    description: "Use this elements, if you want to show some hints or additional information",
  };

  const form: FormConfig = {
    fields: [
      {
        name: "name",
        label: "Name",
        type: "text",
        helpText: "The name to identify the points link. (Not used anywhere else)",
        value: pointLink.name,
        change: (event: React.ChangeEvent<HTMLInputElement>) => setPointLink({ ...pointLink, name: event.target.value }),
        row: true,
      },
      {
        name: "reward",
        label: "Reward",
        type: "number",
        helpText: "The points rewarded when the link is opened by the user",
        value: pointLink.reward,
        change: (event: React.ChangeEvent<HTMLInputElement>) => setPointLink({ ...pointLink, reward: +event.target.value }),
        row: true,
      },
      {
        name: "country",
        label: "Country",
        type: "search-select",
        helpText: "The country the link should be active in.",
        change: (data: string) => setPointLink({ ...pointLink, country: data }),
        value: pointLink.country,
        options: parseMarketCountries(countries),
        empty: "Everywhere",
        row: true,
      },
    ],
    onSubmit,
    submitText: "Save",
    name: "create-points-link",
  };

  return <CreateEditPage loading={false} page={page} form={form} />;
};
