 
import { memo } from "react";
import type { ColumnDef, Table } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";

import { Table as UITable, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { OfferWithCategoriesAndTags } from "~/providers/marketplace";

interface RewardsTableHeaderProps {
  table: Table<OfferWithCategoriesAndTags>;
}

export const RewardsTableHeader = memo(({ table }: RewardsTableHeaderProps) => (
  <TableHeader>
    {table.getHeaderGroups().map((headerGroup) => (
      <TableRow key={headerGroup.id} className="">
        {headerGroup.headers.map((header) => (
          <TableHead key={header.id}>
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </TableHead>
        ))}
      </TableRow>
    ))}
  </TableHeader>
));

RewardsTableHeader.displayName = "RewardsTableHeader";

interface RewardsTableBodyProps {
  table: Table<OfferWithCategoriesAndTags>;
  columns: ColumnDef<OfferWithCategoriesAndTags>[];
  isLoading: boolean;
}

export const RewardsTableBody = memo(({ table, columns, isLoading }: RewardsTableBodyProps) => (
  <TableBody>
    <TableRow className="border-0">
      <TableCell className="p-3" />
    </TableRow>
    {table.getRowModel().rows?.length ? (
      table.getRowModel().rows.map((row) => (
        <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
          {row.getVisibleCells().map((cell) => (
            <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
          ))}
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={columns.length} className="h-24 text-center">
          {isLoading ? "Loading..." : "No results."}
        </TableCell>
      </TableRow>
    )}
  </TableBody>
));

RewardsTableBody.displayName = "RewardsTableBody";

interface RewardsTableProps {
  table: Table<OfferWithCategoriesAndTags>;
  columns: ColumnDef<OfferWithCategoriesAndTags>[];
  isLoading: boolean;
}

export const RewardsTable = memo(({ table, columns, isLoading }: RewardsTableProps) => (
  <UITable>
    <RewardsTableHeader table={table} />
    <RewardsTableBody table={table} columns={columns} isLoading={isLoading} />
  </UITable>
));

RewardsTable.displayName = "RewardsTable";
