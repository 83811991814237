import { ReactNode, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { useCreateTransactionV1TransactionsPost, useGetAllTransactionsV1TransactionsGet } from "~/api/transactions/transactions.gen";
import { getUserDiamondsV1UsersUserIdDiamondsGetQueryOptions, getUserPointV1UsersUserIdPointsGetQueryOptions } from "~/api/users/users.gen";
import { parseFastAPIError } from "~/helpers/parse-errors";
import Button from "~/oldComponents/button/Button";
import { Form } from "~/oldComponents/form/Form";
import type { Field } from "~/oldComponents/form/types";
import { TransactionTable } from "~/pages/transaction";

interface UserTransactionsSectionProps {
  userId: string;
  refetchUser: () => void;
}

export function UserTransactionsSection({
  userId,
  refetchUser,
}: UserTransactionsSectionProps) {
  const [enableTransactions, setEnableTransactions] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const [transactionFilter, setTransactionFilter] = useState<{ sourcetype: string | undefined; offerid: string | undefined }>({
    sourcetype: undefined,
    offerid: undefined,
  });

  const { data: userPoint, refetch: refetchUserPoint } = useQuery({
    ...getUserPointV1UsersUserIdPointsGetQueryOptions(userId),
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return failureCount < 3;
    },
    throwOnError: (error) => {
      return error?.response?.status !== 404;
    },
  });

  const { data: userDiamonds, refetch: refetchUserDiamond } = useQuery({
    ...getUserDiamondsV1UsersUserIdDiamondsGetQueryOptions(userId),
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return failureCount < 3;
    },
    throwOnError: (error) => {
      return error?.response?.status !== 404;
    },
  });

  const {
    data: { items: transactions, total } = {
      items: [],
      total: 0,
    },
    isFetching: loadingTransactions,
    refetch: refetchTransactions,
  } = useGetAllTransactionsV1TransactionsGet(
    {
      user_id: userId,
      source_type: transactionFilter.sourcetype,
      offer_id: transactionFilter.offerid,
      page: pagination.pageIndex + 1,
      limit: pagination.pageSize,
    },
    {
      query: {
        enabled: enableTransactions,
        initialData: undefined,
      },
    },
  );

  const handleSubmit = (userid: string | undefined, sourcetype: string | undefined, offerid: string | undefined) => {
    setTransactionFilter({
      sourcetype,
      offerid,
    });
  };

  const createTransactionMutation = useCreateTransactionV1TransactionsPost({
    mutation: {
      onSuccess: () => {
        toast("Admin points transaction successfully created!");
        setTimeout(() => {
          refetchUser();
          refetchUserPoint();
          refetchUserDiamond();
          if (enableTransactions) {
            refetchTransactions();
          }
        }, 1000);
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onTransactionSubmit = async (event: React.FormEvent) => {
    event?.preventDefault();

    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const pointsAmount = formData.get("pointsAmount");
    const diamondShards = formData.get("diamondShards");
    const numberPointsAmount = Number(pointsAmount);
    const numberDiamondShards = Number(diamondShards);

    if ((numberPointsAmount === 0 && numberDiamondShards === 0) || (isNaN(numberPointsAmount) && isNaN(numberDiamondShards))) {
      return toast("Giving 0 points and 0 diamond shards does not make sense.");
    }

    createTransactionMutation.mutateAsync({
      data: {
        amount: Number(pointsAmount),
        diamond_shards: numberDiamondShards || null,
        user_id: userId,
      },
    });

    form.querySelector("[name='pointsAmount'")?.setAttribute("value", "0");
    form.querySelector("[name='diamondShards'")?.setAttribute("value", "0");
  };

  const transactionFields: Field[] = [
    {
      name: "pointsAmount",
      label: "Points amount",
      helpText: "Provide user certain amount of points.",
      type: "number",
    },
    {
      name: "diamondShards",
      label: "Diamond shards",
      helpText: "Provide user certain amount of diamond shards.",
      type: "number",
    },
  ];

  return (
    <>
      <section className="user-data_section">
        <h5 className="data_section-header">Create admin transaction</h5>
        <Form vertical name="admin-points" fields={transactionFields} onSubmit={onTransactionSubmit} submitText="Create transaction" />
      </section>

      {(userPoint && Object.keys(userPoint.points).length > 0) || (userDiamonds && Object.keys(userDiamonds.diamondShards).length > 0) ? (
        <div className="flex gap-4">
          {userPoint && Object.keys(userPoint.points).length > 0 && (
            <section className="user-data_section flex-1">
              <h3 className="data_section-header">User total points</h3>

              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[300px]">Type</TableHead>
                    <TableHead>Points</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Object.entries(userPoint?.points)
                    .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
                    .map(([type, points]: [string, number]) => (
                      <TableRow key={type}>
                        <TableCell className="font-bold">{type}</TableCell>
                        <TableCell>{points}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </section>
          )}
          {userDiamonds && Object.keys(userDiamonds.diamondShards).length > 0 && (
            <section className="user-data_section flex-1">
              <h3 className="data_section-header">User diamonds</h3>

              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[300px]">Type</TableHead>
                    <TableHead>Points</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {Object.entries(userDiamonds?.diamondShards)
                    .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
                    .map(([type, points]: [string, number]) => (
                      <TableRow key={type}>
                        <TableCell className="font-bold">{type}</TableCell>
                        <TableCell>{points}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </section>
          )}
        </div>
      ) : null}
      <section>
        {enableTransactions ? (
          <TransactionTable
            pagination={pagination}
            setPagination={setPagination}
            initUserId={userId}
            handleSubmit={handleSubmit}
            items={transactions}
            total={total}
            loading={loadingTransactions}
          />
        ) : (
          <Button onClick={() => setEnableTransactions(true)}>Show transactions</Button>
        )}
      </section>
    </>
  );
}
