// eslint-disable-next-line simple-import-sort/imports
import React from "react";
import { Active, DragEndEvent, Over } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { addMonths, subMinutes } from "date-fns";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";

import { DnDDataTable, RowDragHandleCell } from "@/dnd_datatable";
import { Icon } from "@/icon/icon";
import { IconName } from "@/icon/types";
import { Button } from "@/ui/button";
import { Checkbox } from "@/ui/checkbox";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/ui/dialog";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";

import { Reward } from "./CollectionsParts/types";

import { BusinessRuleType, HighlightedCollectionType, OfferStatus, OfferType } from "~/api/model";
import { useUpdateBonusOfferV1OffersBonusOfferOfferIdPut, useUpdateOfferV1OffersOfferIdPut } from "~/api/offers/offers.gen";
import { cn } from "~/lib/utils";
import { getCategoryIcon } from "~/helpers/category";
import formatDate from "~/helpers/date-formatting";
import { prepareBonusOfferUpdate, prepareOfferUpdate } from "~/helpers/offer";
import { parseFastAPIError } from "~/helpers/parse-errors";
import {
  BusinessRuleWithStatusAndCount,
  CategoryWithStatusAndRewardCount,
  OfferWithCategoriesAndTags,
  TagWithStatusAndRewardCount,
  useMarketplace,
} from "~/providers/marketplace";
import {
  useUpdateCategoryV1MarketplaceCategoriesCategoryIdPut,
  useUpdateTagV1MarketplaceTagsTagIdPut,
  useUpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut,
} from "~/api/marketplace/marketplace.gen";
import { ConfirmDialog } from "@/confirm_dialog";
import { format } from "date-fns";
import { DatePicker } from "@/datepicker";
import { Label } from "@/ui/label";
import { Combobox, ComboboxOption } from "@/combobox";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNumber(value: any) {
  if (value === "") {
    return false;
  }
  if (typeof value === "number" && !isNaN(value)) {
    return true;
  }
  if (typeof value === "string" && !isNaN(Number(value))) {
    return true;
  }
  return false;
}

export default function RewardOrderPopup({
  parent,
  parentType,
  refetch,
  openDialog,
  setOpenDialog,
  showDelete,
}: {
  parent: CategoryWithStatusAndRewardCount | TagWithStatusAndRewardCount | BusinessRuleWithStatusAndCount | null;
  parentType: HighlightedCollectionType;
  refetch: () => void;
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showDelete: boolean;
}) {
  const { rewards, activeRewards, newUserSpecialRewards, refetchMarketplace } = useMarketplace();

  const [localState, setLocalState] = useState<string[]>();
  const [rowSelection, setRowSelection] = useState({});
  const [name, setName] = useState<string>(parent?.name || "");
  const [amountOfRewards, setAmountOfRewards] = useState<number | undefined>(undefined);
  const [startDateFilter, setStartDateFilter] = useState<Date | undefined>(undefined);
  const [endDateFilter, setEndDateFilter] = useState<Date | undefined>(undefined);

  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [changeStatusDialogText, setChangeStatusDialogText] = useState("");
  const [changeStatusOffer, setChangeStatusOffer] = useState<OfferWithCategoriesAndTags | null>(null);
  const [changeStatusStatus, setChangeStatusStatus] = useState<OfferStatus | null>(null);

  const [mappedRewards, setMappedRewards] = useState<Reward[]>([]);
  const [columnVisibility, setColumnVisibility] = useState({
    "drag-handle":
      parentType !== HighlightedCollectionType.BusinessRules ||
      (parent && "type" in parent && parent.type === BusinessRuleType.NewUserSpecial) ||
      false,
    select: parentType !== HighlightedCollectionType.BusinessRules,
    rewardType: false,
    platform: false,
    delete:
      parentType !== HighlightedCollectionType.BusinessRules ||
      (parent && "type" in parent && parent.type === BusinessRuleType.NewUserSpecial) ||
      false,
  });

  useEffect(() => {
    setColumnVisibility({
      "drag-handle":
        parentType !== HighlightedCollectionType.BusinessRules ||
        (parent && "type" in parent && parent.type === BusinessRuleType.NewUserSpecial) ||
        false,
      select: parentType !== HighlightedCollectionType.BusinessRules,
      rewardType: false,
      platform: false,
      delete:
        parentType !== HighlightedCollectionType.BusinessRules ||
        (parent && "type" in parent && parent.type === BusinessRuleType.NewUserSpecial) ||
        false,
    });
  }, [parent, parentType]);

  const categoryRewardUpdateMutation = useUpdateCategoryV1MarketplaceCategoriesCategoryIdPut({
    mutation: {
      onSuccess: () => {
        toast("Reward updated successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const tagRewardUpdateMutation = useUpdateTagV1MarketplaceTagsTagIdPut({
    mutation: {
      onSuccess: () => {
        toast("Reward updated successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const businessRuleRewardOrderUpdateMutation = useUpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut({
    mutation: {
      onSuccess: () => {
        toast("Reward updated successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const updateOfferMutation = useUpdateOfferV1OffersOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
      onSuccess: () => {
        toast("Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  const updateBonusOfferMutation = useUpdateBonusOfferV1OffersBonusOfferOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
      onSuccess: () => {
        toast("Bonus Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  useEffect(() => {
    setName(parent?.name || "");

    if (parent && openDialog) {
      if ("amountOfRewards" in parent) {
        setAmountOfRewards(parent?.amountOfRewards || -1);
      }
      if ("startDateFilter" in parent) {
        setStartDateFilter(parent.startDateFilter ? new Date(parent.startDateFilter) : undefined);
      }
      if ("endDateFilter" in parent) {
        setEndDateFilter(parent.endDateFilter ? new Date(parent.endDateFilter) : undefined);
      }
    } else {
      setAmountOfRewards(-1);
      setStartDateFilter(undefined);
      setEndDateFilter(undefined);
    }

    const rewardOrder = parent?.rewardOrder || [];
    setLocalState(rewardOrder);

    if (!openDialog) {
      setRowSelection({});
    }
  }, [parent, openDialog]);

  useEffect(() => {
    if (!localState) {
      setMappedRewards([]);
    } else if (localState.length > 0 && parent && "type" in parent && parent.type === BusinessRuleType.NewUserSpecial) {
      // todo: use newUserSpecialRewards
      setMappedRewards(
        newUserSpecialRewards
          .filter((offer: OfferWithCategoriesAndTags) => {
            return localState.includes(offer._id?.toString() || "");
          })
          .sort((a, b) => localState.indexOf(a._id?.toString() || "") - localState.indexOf(b._id?.toString() || ""))
          .map((offer) => {
            return {
              id: offer._id,
              title: `${offer.titleV2} | ${offer.subTitle}`,
              status: offer.status,
              logoUrl: offer.logoUrl || "",
              hideInList: offer.hideInList,
              points: offer.price,
              used: 0,
              available: offer.amount || 0,
              categories: offer.categories.map((category) => getCategoryIcon(category.category)) || [],
              categoryString: offer.categoryIds.join(",") || "",
              tags: offer.tags.map((tag) => tag.name) || [],
              tagString: offer.tags.map((tag) => tag.name).join(",") || "",
              schedule: {
                start: offer.publishDate || "?",
                end: offer.unPublishDate || "?",
              },
              codes: offer.coupon_count,
              verified: offer.verified || 0,
              order: localState.findIndex((offerId: string) => offerId === offer._id),
              rewardType: offer.type || OfferType.Regular,
              platform: `${offer.platform.android ? "Android" : ""} ${offer.platform.ios ? "iOS" : ""}`,
              deeplink: offer.deeplink,
              offer,
            } as Reward;
          }),
      );
    } else if (localState.length > 0) {
      setMappedRewards(
        rewards
          .filter((offer: OfferWithCategoriesAndTags) => {
            return localState.includes(offer._id?.toString() || "");
          })
          .sort((a, b) => localState.indexOf(a._id?.toString() || "") - localState.indexOf(b._id?.toString() || ""))
          .map((offer) => {
            return {
              id: offer._id,
              title: `${offer.titleV2} | ${offer.subTitle}`,
              status: offer.status,
              logoUrl: offer.logoUrl || "",
              hideInList: offer.hideInList,
              points: offer.price,
              used: 0,
              available: offer.amount || 0,
              categories: offer.categories.map((category) => getCategoryIcon(category.category)) || [],
              categoryString: offer.categoryIds.join(",") || "",
              tags: offer.tags.map((tag) => tag.name) || [],
              tagString: offer.tags.map((tag) => tag.name).join(",") || "",
              schedule: {
                start: offer.publishDate || "?",
                end: offer.unPublishDate || "?",
              },
              codes: offer.coupon_count,
              verified: offer.verified || 0,
              order: localState.findIndex((offerId: string) => offerId === offer._id),
              rewardType: offer.type || OfferType.Regular,
              platform: `${offer.platform.android ? "Android" : ""} ${offer.platform.ios ? "iOS" : ""}`,
              deeplink: offer.deeplink,
              offer,
            } as Reward;
          }),
      );
    } else {
      setMappedRewards([]);
    }
  }, [localState, rewards, parent, newUserSpecialRewards]);

  function prepareStatusUpdate(offer: OfferWithCategoriesAndTags, status: OfferStatus) {
    if (offer.status === status) return;

    let statusText = `Are you sure you want to change the status of the reward "${offer.titleV2}" from <br/>"${offer.status}" to "${status}"?`;

    const now = new Date();
    let unpublishDate = new Date(offer.unPublishDate || now);
    if (offer.status === OfferStatus.Unpublished && status === OfferStatus.Published) {
      if (unpublishDate < now) {
        unpublishDate = addMonths(now, 3);
      }
      statusText += `|We will also set the publishDate to "${formatDate(now)}" and we will set the unpublishDate to "${formatDate(unpublishDate)}".`;
    }

    if (offer.status === OfferStatus.Published && status === OfferStatus.Unpublished) {
      if (unpublishDate > now) {
        unpublishDate = subMinutes(now, 5);
      }
      statusText += `|We will also set the unpublishDate to "${formatDate(unpublishDate)}".`;
    }

    if (status === OfferStatus.Archived) {
      statusText += `|This will remove the reward from the marketplace. This will set the publish and unpublish date to "${formatDate(now)}".`;
    }

    setChangeStatusDialogText(statusText);
    setChangeStatusOffer(offer);
    setChangeStatusStatus(status);
    setShowChangeStatusDialog(true);
  }

  function updateStatus() {
    if (!changeStatusOffer || !changeStatusStatus) return null;
    // TODO: Update the table to reflect the new status without needing to reload the data from the backend
    //
    const additionalUpdateFields: { publishDate?: string; unPublishDate?: string } = {};
    const now = new Date();
    const nowString = now.toISOString();
    const oneMonthLater = addMonths(now, 3).toISOString();
    if (changeStatusOffer.status === OfferStatus.Unpublished && changeStatusStatus === OfferStatus.Published) {
      additionalUpdateFields["publishDate"] = nowString;
      if (!changeStatusOffer.unPublishDate || changeStatusOffer.unPublishDate < nowString) {
        additionalUpdateFields["unPublishDate"] = oneMonthLater;
      }
    }

    if (changeStatusOffer.status === OfferStatus.Published && changeStatusStatus === OfferStatus.Unpublished) {
      const unpublishDate = subMinutes(now, 5);
      additionalUpdateFields["unPublishDate"] = unpublishDate.toISOString();
    }

    if (changeStatusStatus === OfferStatus.Archived) {
      additionalUpdateFields["publishDate"] = nowString;
      additionalUpdateFields["unPublishDate"] = nowString;
    }

    setMappedRewards((offers) => {
      return offers.map((lOffer: Reward) => {
        if (lOffer.id === changeStatusOffer._id) return { ...lOffer, status: changeStatusStatus, ...additionalUpdateFields };
        return lOffer;
      });
    });

    if (changeStatusOffer.isBonus) {
      return updateBonusOfferMutation.mutateAsync({
        offerId: changeStatusOffer._id as string,
        data: {
          ...prepareBonusOfferUpdate(changeStatusOffer),
          status: changeStatusStatus,
          ...additionalUpdateFields,
        },
      });
    } else {
      return updateOfferMutation.mutateAsync({
        offerId: changeStatusOffer._id as string,
        data: { ...prepareOfferUpdate(changeStatusOffer), status: changeStatusStatus, ...additionalUpdateFields },
      });
    }
  }

  function addToList(id: string) {
    rewardTable.getColumn("title")?.setFilterValue("");
    if (localState) {
      setLocalState([...localState, id]);
    } else {
      setLocalState([id]);
    }
  }

  const rewardColumns = useMemo<ColumnDef<Reward>[]>(() => {
    function updateHideInList(offer: OfferWithCategoriesAndTags, hideInList: boolean) {
      if (!offer._id) return;

      setMappedRewards((offers) => {
        return offers.map((lOffer: Reward) => {
          if (lOffer.id === offer._id) return { ...lOffer, hideInList };
          return lOffer;
        });
      });

      if (offer.isBonus) {
        updateBonusOfferMutation.mutateAsync({
          offerId: offer._id,
          data: {
            ...prepareBonusOfferUpdate(offer),
            hideInList,
          },
        });
      } else {
        updateOfferMutation.mutateAsync({
          offerId: offer._id,
          data: {
            ...prepareOfferUpdate(offer),
            hideInList,
          },
        });
      }
    }
    return [
      {
        id: "drag-handle",
        header: "",
        cell: ({ row }) => {
          if (
            parentType === HighlightedCollectionType.BusinessRules &&
            parent &&
            "type" in parent &&
            parent.type !== BusinessRuleType.NewUserSpecial
          ) {
            return null;
          }
          return <RowDragHandleCell rowId={row.id} />;
        },
      },
      {
        id: "select",
        header: ({ table }) => {
          if (parentType === HighlightedCollectionType.BusinessRules) {
            return null;
          }
          return (
            <Checkbox
              checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
            />
          );
        },
        cell: ({ row }) => {
          if (parentType === HighlightedCollectionType.BusinessRules) {
            return null;
          }
          if (parentType === HighlightedCollectionType.Category && row.original.categories.length <= 1) {
            return (
              <Checkbox
                title="Reward only has one category. So we can not remove it from this category"
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                disabled
              />
            );
          }
          return <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />;
        },
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "logoUrl",
        header: "",
        cell: ({ row }) => {
          const logo: string = row.getValue("logoUrl");
          if (logo) {
            return <img src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${logo}`} className="h-10 min-h-10 w-10 min-w-10 rounded-full" />;
          }
        },
      },
      {
        accessorKey: "title",
        header: "Reward",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => {
          return (
            <Select onValueChange={(value) => prepareStatusUpdate(row.original.offer, value as OfferStatus)} defaultValue={row.original.status}>
              <SelectTrigger
                className={cn(
                  "justify-center gap-1 rounded-full bg-archived p-2 text-archived-foreground",
                  row.original.status === "Published" && "bg-published text-published-foreground",
                  row.original.status === "Unpublished" && "bg-unpublished text-unpublished-foreground",
                )}
              >
                <SelectValue placeholder="Select a status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={OfferStatus.Published}>Published</SelectItem>
                <SelectItem value={OfferStatus.Unpublished}>Unpublished</SelectItem>
                <SelectItem value={OfferStatus.Archived}>Archived</SelectItem>
              </SelectContent>
            </Select>
          );
        },
      },
      {
        accessorKey: "hideInList",
        header: "Hidden",
        cell: ({ row }) => {
          return (
            <Checkbox checked={row.original.hideInList} onCheckedChange={(checked) => updateHideInList(row.original.offer, checked as boolean)} />
          );
        },
      },
      {
        accessorKey: "points",
        header: "Points",
      },
      {
        id: "available",
        header: () => {
          return (
            <div>
              Total Inventory
              <br />
              <small>Available/Claimed</small>
            </div>
          );
        },
        cell: ({ row }) => {
          return (
            <div>
              {row.original.offer.amount}
              <br />
              <small>
                {row.original.offer.amount - (row.original.verified || 0)}/{row.original.verified}
              </small>
            </div>
          );
        },
      },
      {
        id: "codes",
        header: () => {
          return (
            <div>
              Codes
              <br />
              <small>Unused/Used</small>
            </div>
          );
        },
        cell: ({ row }) => (
          <div>
            {row.original.offer.unused_coupon_count}/{(row.original.offer.coupon_count || 0) - (row.original.offer.unused_coupon_count || 0)}
          </div>
        ),
      },
      {
        accessorKey: "categories",
        header: () => "Categories",
        cell: ({ row }) => {
          const categories: IconName[] = row.getValue("categories");
          return (
            <div className="flex items-center gap-4">
              {categories.map((category: IconName) => (
                <div key={category} className="rounded-md bg-accent p-2 text-accent-foreground">
                  <Icon className="h-5 w-5" icon={category} />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "tags",
        header: () => "Tags",
        cell: ({ row }) => {
          const tags: string[] = row.getValue("tags");
          return tags.map((tag: string) => <div key={tag}>{tag}</div>);
        },
      },
      {
        accessorKey: "schedule",
        header: () => "Schedule",
        cell: ({ row }) => {
          const schedule: { start: string; end: string } = row.getValue("schedule");
          return (
            <div className="flex flex-col gap-2">
              <div>Start: {formatDate(schedule.start) as ReactNode}</div>
              <div>End: {formatDate(schedule.end) as ReactNode}</div>
            </div>
          );
        },
      },
      {
        id: "delete",
        cell: ({ row }) => {
          if (
            (parentType === HighlightedCollectionType.Category && row.original.categories.length <= 1) ||
            (parentType === HighlightedCollectionType.BusinessRules && parent && "type" in parent && parent.type !== BusinessRuleType.NewUserSpecial)
          ) {
            return null;
          }
          return (
            <Button
              variant="ghost"
              onClick={() => {
                setLocalState((data) => data?.filter((reward) => reward !== row.id));
                setMappedRewards((data) => data.filter((reward) => reward.id !== row.id));
              }}
            >
              <Icon icon="Close" />
            </Button>
          );
        },
      },
    ];
  }, [parentType, parent, updateBonusOfferMutation, updateOfferMutation]);

  const rewardTable = useReactTable({
    data: mappedRewards,
    columns: rewardColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id,
    enableRowSelection: (row) => parentType === HighlightedCollectionType.Category && row.original.categories.length > 1,
    onRowSelectionChange: setRowSelection,
    state: {
      columnVisibility,
      rowSelection,
    },
  });

  function saveRewardChanges() {
    if (parentType === HighlightedCollectionType.Category) {
      categoryRewardUpdateMutation.mutate({
        categoryId: parent?._id as string,
        data: {
          rewardOrder: mappedRewards.map((reward) => reward.id),
        },
      });
    } else if (parentType === HighlightedCollectionType.Tag) {
      tagRewardUpdateMutation.mutate({
        tagId: parent?._id as string,
        data: {
          name,
          rewardOrder: mappedRewards.map((reward) => reward.id),
        },
      });
    } else if (parentType === HighlightedCollectionType.BusinessRules) {
      businessRuleRewardOrderUpdateMutation.mutate({
        ruleId: parent?._id as string,
        data: {
          name,
          rewardOrder: mappedRewards.map((reward) => reward.id),
          amountOfRewards: isNumber(amountOfRewards) ? amountOfRewards : undefined,
          startDateFilter: startDateFilter ? format(startDateFilter, "yyyy-MM-dd") : undefined,
          endDateFilter: endDateFilter ? format(endDateFilter, "yyyy-MM-dd") : undefined,
        },
      });
    } else {
      toast.info("Parent Type is unknow. So can not update");
    }
    setTimeout(() => {
      refetch();
      setOpenDialog(false);
    }, 500);
  }

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over }: { active: Active; over: Over | null } = event;

    if (active && over && active.id !== over.id) {
      setLocalState((data) => {
        if (data) {
          const oldIndex = data.findIndex((reward) => reward === active.id);
          const newIndex = data.findIndex((reward) => reward === over.id);
          return arrayMove(data, oldIndex, newIndex);
        }
      });
      setMappedRewards((data) => {
        const oldIndex = data.findIndex((reward) => reward.id === active.id);
        const newIndex = data.findIndex((reward) => reward.id === over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }
  }

  function deleteSelectedRewards() {
    const selectedRows = rewardTable.getSelectedRowModel().rows;

    const selectedIds: string[] = selectedRows.map((row) => row.original.id as string);
    setLocalState((data) => data?.filter((reward) => !selectedIds.includes(reward)));
    setMappedRewards((data) => data.filter((reward) => !selectedIds.includes(reward.id)));
    setRowSelection([]);
  }

  useEffect(() => {
    rewardTable.getColumn("title")?.setFilterValue("");
    if (!openDialog) {
      setLocalState(undefined);
    }
  }, [openDialog, rewardTable]);

  const additionalData =
    parentType !== HighlightedCollectionType.BusinessRules || (parent && "type" in parent && parent.type !== BusinessRuleType.NewUserSpecial)
      ? activeRewards
      : newUserSpecialRewards;

  if (!parent) return null;
  return (
    <div>
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="max-w-10/12 w-10/12" hideClose>
          <DialogHeader>
            <DialogTitle className="text-bold flex items-center justify-between">
              {parentType === HighlightedCollectionType.Category ? (
                name
              ) : (
                <div className="flex items-center gap-1">
                  <Input value={name} onChange={(event) => setName(event.currentTarget.value)} className="[field-sizing:content]" />
                  <Icon icon="Edit" />
                </div>
              )}
              <div className="flex items-center gap-10">
                <Input
                  placeholder="Search"
                  value={(rewardTable.getColumn("title")?.getFilterValue() as string) || ""}
                  onChange={(event) => {
                    rewardTable.getColumn("title")?.setFilterValue(event.target.value);
                  }}
                />
                <DialogClose asChild>
                  <Button variant="ghost">
                    <Icon icon="Close" />
                  </Button>
                </DialogClose>
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-row-reverse items-end justify-between">
            <div className="w-1/3">
              {(parentType !== HighlightedCollectionType.BusinessRules || ("type" in parent && parent.type === BusinessRuleType.NewUserSpecial)) && (
                <Combobox
                  key={parentType}
                  searchName="Search"
                  placeholder="Search to add"
                  options={additionalData
                    .filter((data) => !rewardTable.options.data.find((offer: Reward) => offer.id.toString() === data._id?.toString()))
                    .map((offer) => ({ label: `${offer.titleV2} - ${offer.subTitle}`, value: offer.id }))}
                  onChange={(value: ComboboxOption) => {
                    addToList(value.value as string);
                  }}
                />
              )}
            </div>
            {parentType === HighlightedCollectionType.BusinessRules && "type" in parent && parent.type !== BusinessRuleType.All && (
              <div className="w-1/3">
                {"type" in parent && parent.type === BusinessRuleType.MostSold && (
                  <div className="flex items-center space-x-2">
                    <Label htmlFor="terms">Amount of rewards</Label>
                    <Input
                      type="number"
                      value={amountOfRewards}
                      onChange={(event) => {
                        if (isNumber(event.currentTarget.value)) {
                          setAmountOfRewards(+event.currentTarget.value);
                        } else {
                          setAmountOfRewards(undefined);
                        }
                      }}
                      className="[field-sizing:content]"
                    />
                  </div>
                )}
                {"type" in parent && parent.type === BusinessRuleType.NewUserSpecial && (
                  <>
                    <div className="flex items-center space-x-2">
                      <Label htmlFor="terms">Start date filter</Label>
                      <DatePicker
                        key={startDateFilter?.toUTCString()}
                        defaultValue={startDateFilter}
                        onChange={(date: Date | undefined) => setStartDateFilter(date)}
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <Label htmlFor="terms">End date filter</Label>
                      <DatePicker
                        key={endDateFilter?.toUTCString()}
                        defaultValue={endDateFilter}
                        onChange={(date: Date | undefined) => setEndDateFilter(date)}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="overflow-x-auto">
            <div className="max-h-[60dvh] overflow-y-auto">
              <DnDDataTable table={rewardTable} columns={rewardColumns} handleDragEnd={handleDragEnd} dataIds={localState || []} />
            </div>
          </div>
          <DialogFooter>
            {Object.keys(rowSelection).length > 0 && (
              <Button variant="destructive" onClick={deleteSelectedRewards}>
                Delete Rewards from {parent?.name}
              </Button>
            )}
            {showDelete && (
              <Button onClick={() => null} variant="destructive">
                Delete
              </Button>
            )}
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button onClick={saveRewardChanges}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        openDialog={showChangeStatusDialog}
        setOpenDialog={setShowChangeStatusDialog}
        title={changeStatusDialogText}
        confirmAction={updateStatus}
        confirmText={`set to ${changeStatusStatus}`}
      />
    </div>
  );
}
