import React from "react";
import { useState } from "react";
import { closestCenter, DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";

import { SortableCategory } from "@/sortable/SortableCategory";

import RewardOrderPopup from "../RewardOrder";

import { HighlightedCollectionType } from "~/api/model";
import { CategoryWithStatusAndRewardCount, useMarketplace } from "~/providers/marketplace";

const CategoriesPart = ({
  categories,
  updateCategories,
}: {
  categories: CategoryWithStatusAndRewardCount[];
  updateCategories: React.Dispatch<React.SetStateAction<CategoryWithStatusAndRewardCount[]>>;
}) => {
  const { refetchMarketplace } = useMarketplace();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<CategoryWithStatusAndRewardCount | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (over && active.id !== over?.id) {
      const newOrder = arrayMove(categories, active.data.current?.sortable.index, over.data.current?.sortable.index);
      updateCategories(newOrder);
    }
  }

  const openDialogFn = (category: CategoryWithStatusAndRewardCount) => {
    setSelectedCategory(category);
    setOpenDialog(true);
  };

  return (
    <div className="flex flex-col gap-6">
      <h3>Categories</h3>

      <div className="relative flex flex-wrap gap-4">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={categories.map(({ _id }) => _id as string)} strategy={horizontalListSortingStrategy}>
            {categories.map((category) => (
              <SortableCategory
                key={`category-${category._id}`}
                id={category._id as string}
                categoryType={category.category}
                rewardOrderCount={category.publishedCount}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();

                  openDialogFn(category);
                }}
              />
            ))}
          </SortableContext>
        </DndContext>
        {selectedCategory &&
          <RewardOrderPopup
            parent={selectedCategory}
            parentType={HighlightedCollectionType.Category}
            refetch={refetchMarketplace}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            showDelete={false}
          />
        }
      </div>
    </div>
  );
};

export default CategoriesPart;
