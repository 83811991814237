import React from "react";
import { memo } from "react";
import { useNavigate, UseNavigateResult } from "@tanstack/react-router";

import MultiSelectFormField from "@/multi_select";
import { Button } from "@/ui/button";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectSeparator, SelectTrigger, SelectValue } from "@/ui/select";

import { RewardSearch } from "./Rewards";

import { OfferFilterStatus, OfferType } from "~/api/model";
import { getCategoryName } from "~/helpers/category";
import { useMarketplace } from "~/providers/marketplace";

type RewardsFilterProps = {
  search: RewardSearch;
  onStatusChange?: (value: string) => void;
};

// TODO: Fix the any props
export const RewardsFilter = memo(({ search, onStatusChange }: RewardsFilterProps) => {
  const { categories, tags } = useMarketplace();
  const navigate: UseNavigateResult<"/$market/marketplace/"> = useNavigate();

  const [searchText, setSearchText] = React.useState(search.search);

  return (
    <form className="flex w-full flex-col gap-10 rounded-lg bg-accent p-4">
      <div className="flex gap-2">
        <Input
          placeholder="Search"
          value={searchText}
          onChange={(event) => {
            // table.getColumn("fullTitle")?.setFilterValue(event.target.value);
            setSearchText(event.target.value);
            // Removed immediate navigation on change
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, search: searchText }) });
            }
          }}
        />
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, search: searchText }) });
          }}
        >
          Search
        </Button>
      </div>
      <div className="grid w-full grid-cols-5 items-center gap-10">
        <Select
          onValueChange={(value) => {
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, status: value as OfferFilterStatus }) });
            if (onStatusChange) onStatusChange(value);
          }}
          defaultValue={(search.status as string) ?? OfferFilterStatus.Active}
        >
          <SelectTrigger>
            <SelectValue placeholder="Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={OfferFilterStatus.Active}>Active</SelectItem>
            <SelectItem value={OfferFilterStatus.Published}>Published</SelectItem>
            <SelectItem value={OfferFilterStatus.Unpublished}>Unpublished</SelectItem>
            <SelectItem value={OfferFilterStatus.Archived}>Archived</SelectItem>
          </SelectContent>
        </Select>
        <Select
          onValueChange={(value) => {
            // table.getColumn("rewardType")?.setFilterValue(value);
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, rewardType: value }) });
          }}
          value={(search.rewardType as string) ?? OfferType.Regular}
        >
          <SelectTrigger>
            <SelectValue placeholder="Reward type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={OfferType.Regular}>Rewards only</SelectItem>
            <SelectItem value={OfferType.Bonus}>Bonus Rewards only</SelectItem>
            <SelectItem value={OfferType.NewUserSpecialOffer}>New user special Rewards only</SelectItem>
            <SelectSeparator />
            <Button
              className="w-full px-2"
              variant="secondary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, rewardType: undefined }) });
                // table.getColumn("rewardType")?.setFilterValue(undefined);
              }}
            >
              Clear
            </Button>
          </SelectContent>
        </Select>
        <Select
          onValueChange={(value) => {
            // table.getColumn("platformString")?.setFilterValue(value);
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, platform: value }) });
          }}
          value={(search.platform as string) ?? ""}
        >
          <SelectTrigger>
            <SelectValue placeholder="Platform" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="ios">iOS only</SelectItem>
            <SelectItem value="android">Android only</SelectItem>
            <SelectSeparator />
            <Button
              className="w-full px-2"
              variant="secondary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, platform: undefined }) });
                // table.getColumn("platformString")?.setFilterValue(undefined);
              }}
            >
              Clear
            </Button>
          </SelectContent>
        </Select>
        <MultiSelectFormField
          singleLine
          placeholder="Categories"
          options={categories.map((category) => ({ label: getCategoryName(category.category) || "", value: category.category }))}
          defaultValue={(search.categories as string[] | undefined) ?? search.categories}
          onValueChange={(value) => {
            // table.getColumn("categories")?.setFilterValue(value);
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, categories: value }) });
          }}
        />
        <MultiSelectFormField
          singleLine
          placeholder="Tags"
          options={tags.map((tag) => ({ label: tag.name, value: tag._id as string }))}
          defaultValue={(search.tags as string[] | undefined) ?? search.tags}
          onValueChange={(value) => {
            // table.getColumn("tags")?.setFilterValue(value);
            navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, tags: value }) });
          }}
        />
      </div>
    </form>
  );
});

RewardsFilter.displayName = "RewardsFilter";
