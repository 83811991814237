import { ReactNode } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import "~/pages/Users/user.css";

import { DeleteOptions } from "~/api/model";
import {
  getGetUserByIdV1UsersUserIdGetQueryOptions,
  useDeleteUserV1UsersUserIdDelete,
} from "~/api/users/users.gen";
import { PersonalDataSection } from "~/components/users/PersonalDataSection";
import { StreakSection } from "~/components/users/StreakSection";
import { SuspiciousActivitySection } from "~/components/users/SuspiciousActivitySection";
import { UserTransactionsSection } from "~/components/users/UserTransactionsSection";
import { parseFastAPIError } from "~/helpers/parse-errors";
import Button from "~/oldComponents/button/Button";
import PageLayout from "~/oldComponents/layout/PageLayout";
import { useAuthStore } from "~/store/authStore";

export const Route = createFileRoute("/_auth/users/$userId")({
  component: () => <UserPage />,
});

function UserPage() {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userId = Route.useParams().userId;
  const loggedInUser = useAuthStore((state) => state.user);

  const {
    data: user,
    isFetching,
    refetch,
  } = useQuery({
    ...getGetUserByIdV1UsersUserIdGetQueryOptions(userId),
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return failureCount < 3;
    },
    throwOnError: (error) => {
      return error?.response?.status !== 404;
    },
  });

  const deleteUserMutation = useDeleteUserV1UsersUserIdDelete({
    mutation: {
      onMutate: () => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
      },
      onSuccess: () => {
        toast("User was successfully deleted!");
        navigate({
          to: "/users",
          search: {
            page: 1,
            limit: 100,
          },
        });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onUserDelete = async (type: DeleteOptions) => {
    if (userId && userId !== loggedInUser?.user_id) {
      await deleteUserMutation.mutateAsync({ userId, params: { delete_type: type } });
    } else {
      toast.error("Error", { description: "You can not delete yourself." });
    }
  };

  const additionalActions = () => {
    return (
      <Button onClick={() => onUserDelete(DeleteOptions.Hard)} disabled={isFetching} styleType="danger">
        Hard delete
      </Button>
    );
  };

  return (
    <PageLayout
      loading={isFetching}
      title="Stepler user"
      description="Use this elements, if you want to show some hints or additional information"
      onDeleteClick={() => onUserDelete(DeleteOptions.Soft)}
      onDeleteText="Soft delete"
      additionalActions={additionalActions()}
    >
      <section className="flex flex-col gap-8">
        {user && <PersonalDataSection
          user={user}
          refetch={refetch}
        />}

        {user && <SuspiciousActivitySection
          user={user}
          refetch={refetch}
        />}

        {user && user.streak && <StreakSection
          userId={userId}
          streak={user.streak}
        />}

        <UserTransactionsSection
          userId={userId}
          refetchUser={refetch}
        />
      </section>
    </PageLayout>
  );
}
